const login = () => import('../../views/login/login.vue');
const index = () => import('../../views/home/index.vue');
const findmima = () => import('../../views/login/findmima.vue');
const zz = () => import('../../views/login/zz.vue');
export default [{
    path: '/',
    name: 'index',
    component: index, redirect: '/home'
},{
    path: '/login',
    name: 'login',
    component: login
},
    {
        path: '/findmima',
        name: 'findmima',
        component: findmima
    },
    {
        path: '/zz',
        name: 'zz',
        component: zz
    },
];
