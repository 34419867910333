import {$get, $post} from '../http';
import {baseUrl} from "@/request/global";

var api = baseUrl
export const humanindex = (params) => {
    return $get(api+'/idsp-pc/human-resources/index',params)
}
export const humanpageindex = (params) => {
    return $get(api+'/idsp-pc/human-resources/page/index',params)
}
export const humanpersonnelpage = (params) => {
    return $get(api+'/idsp-pc/human-resources/personnel/page',params)
}
export const humanrecruitpage = (params) => {
    return $get(api+'/idsp-pc/human-resources/recruit/page',params)
}
export const humanpersonneldetail = (params) => {
    return $get(api+'/idsp-pc/human-resources/personnel/detail',params)
}
export const humanrecruitdetail = (params) => {
    return $get(api+'/idsp-pc/human-resources/recruit/detail',params)
}
export const humanrecruitapply= (params) => {
    return $post(api+'/idsp-pc/human-resources/recruit/apply',params)
}
export const humanIsCollected= (params) => {
    return  $get(api+'/idsp-pc/collect/isCollected',params)
}
export const humanCollect= (params) => {
    return $post(api+'/idsp-pc/collect/collectArticle',params)
}
export const humanCancelCollect= (params) => {
    return $post(api+'/idsp-pc/collect/cancelCollectArticle',params)
}
