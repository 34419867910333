import axios from 'axios';
import Cookies from 'js-cookie'
import {Base64} from 'js-base64';
import config from '../utils/config'
import common from '../static/js/global'
// 弹框
import {Loading, MessageBox} from 'element-ui';
// element UI 提示信息
export const Toasts = require('element-ui/lib/message').default;
let loadMsg=null
axios.defaults.timeout = 60000;

axios.defaults.withCredentials=true

// 获取发送Url参数
export const getParams = (url, data = {}, method, mode,type) => {
    let methodData = {
        method
    };
    if (method == 'get') {
        methodData = {
            ...methodData,
            params: data
        }
    } else if (method == 'post') {
        methodData = {
            ...methodData,
            data
        }
    }
    if(type==2){
        return {
            url:url,
            ...methodData,
            responseType:'blob',
            headers: {
                'Content-Type':'application/json;charset=UTF-8',
                "t":Date.parse(new Date())
            }
        }
    }else{
        return {
            url: url,
            ...methodData,
            headers: {
                'Content-Type':type==1?'text/plain;charset=UTF-8': 'application/json;charset=UTF-8',
                "t":Date.parse(new Date()),
                'Authorization': type != 3 ? `Basic ${Base64.encode(`${config.clientId}:${config.clientKey}`)}` : '',
                'Tenant-Id': Cookies.get('Tenant-Id'),
                'Blade-Auth': 'bearer ' + Cookies.get('saber-access-token')
            }
        }
    }
}

export const $get = (url, params = {}, option = {}) => {
    option.method = 'get';
    return axiosHandle(url, params, option);
}

export const $post = (url, params = {}, option = {}) => {
    option.method = 'post';
    return axiosHandle(url, params, option)
}

export const $mapget = (url, params = {}, option = {}) => {
    return axios(url).then(function (res) {
        return res
    })
}
let needLoadingRequestCount = 0
export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        loadMsg=Loading.service({
            background:'rgba(255,255,255,0.3)'
        });
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        loadMsg.close();
    }
}

//处理axios请求
const axiosHandle = (url, params, option) => {
    option.mode = option.mode || 'api' ;
    option = Object.assign({}, {
        isLoading: true,
        isToast: true
    }, option);
    axios.defaults.showLoading = option.isLoading;
    // 请求拦截器
    axios.interceptors.request.use((config) => {
        if (config.showLoading) {
            showFullScreenLoading()
        }
        return config
    })
    // 响应拦截器
    axios.interceptors.response.use((response) => {
        if (response.config.showLoading) {
            tryHideFullScreenLoading()
        }
        return response
    })
    return new Promise((resolve, reject) => {
        axios({
            ...getParams(url, params, option.method, option.mode, option.type)
        }).then(res => {
            if(res.data.type&&!res.data.code){
                donload(res)
                return
            }
            let data = resHandle(res, option);
            if (data == false) {
                reject(res);
            } else {
                resolve(data);
            }
        }).catch((error) => {
            reject(error);
            needLoadingRequestCount=0
            return errorHandle(error, option);
        });
    })
}
//文件下载
const donload = (res) => {
    let blob = new Blob([res.data], {type: res.data.type})
    let downloadElement = document.createElement('a')
    let href = window.URL.createObjectURL(blob); //创建下载的链接
    let fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
    fileName = decodeURIComponent(fileName);
    downloadElement.href = href;
    downloadElement.download = fileName;
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放blob对象
}
//正常返回参数处理
const resHandle = (res, option) => {
    // if (option.isLoading) Loading_msg('close');
    if (res.data && !!res.data.code && res.data.code !== 200) {
        if ( res.data.code === '401') { //登录态丢失
            Toasts({
                message:res.data.msg|| '网络异常，稍后再试',
                position: 'center',
                type:'error',
                duration: 3000
            });
        }
        if (option.isToast) { //默认toast弹框
            Toasts({
                message: res.data.msg|| '网络异常，稍后再试',
                position: 'center',
                type:'error',
                duration: 2000
            });
            return false;
        }
    }
    return res.data
}

//异常数据处理
const errorHandle = (error, option) => {
    if (option.isLoading)  loadMsg.close();
    if(error.response){
        if(error.response.status=='401'){
            //window.location.replace(config.pchref)
            common.removeSessionStorage('userInfo')
            MessageBox.confirm('您还未登录, 是否去登录?', '提示', {
                confirmButtonText: '去登录',
                cancelButtonText: '继续浏览',
                type: 'warning'
            }).then(() => {
                window.location.replace(config.pchref)
            }).catch(() => {
                window.location.reload()
            })
        }
    }
    if (option.isToast) {
        Toasts({
            message: error.response.data.msg,
            type:'error',
            position: 'center',
            duration: 2000
        });
    }
    return {
        resultCode: "404"
    }
}
