const logistics = () => import('../../views/logistics/index.vue');
const logisticslist = () => import('../../views/logistics/list.vue');
const wllist = () => import('../../views/logistics/wllist.vue');
const wlxq = () => import('../../views/logistics/wlxq.vue');
const logisticsxq = () => import('../../views/logistics/logisticsxq.vue');
const index = () => import('../../views/home/index.vue');
export default [ {
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/logistics',
        name: 'logistics',
        component: logistics,
    },{
        path: '/logisticslist',
        name: 'logisticslist',
        component: logisticslist,
    },{
        path: '/logisticsxq',
        name: 'logisticsxq',
        component: logisticsxq,
    },{
        path: '/wllist',
        name: 'wllist',
        component: wllist,
    },{
        path: '/wlxq',
        name: 'wlxq',
        component: wlxq,
    }]
}
];
