import {$get} from '../http';
import {baseUrl} from "@/request/global";

var api = baseUrl
export const expertpage = (params) => {
    return $get(api+'/idsp-pc/expert/page',params)
}
export const expertindex = (params) => {
    return $get(api+'/idsp-pc/expert/index',params)
}
export const expertdetail = (params) => {
    return $get(api+'/idsp-pc/expert/detail',params)
}
