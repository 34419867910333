import {$get, $post} from '../http';
import {baseUrl} from "@/request/global";

var api = baseUrl
export const warehouselist = (params) => {
    return $get(api+'/idsp-pc/warehouse/page',params)
}
export const logisticspage = (params) => {
    return $get(api+'/idsp-pc/logistics/page',params)
}
export const warehousedetail = (params) => {
    return $get(api+'/idsp-pc/warehouse/detail',params)
}
export const logisticsdetail = (params) => {
    return $get(api+'/idsp-pc/logistics/detail',params)
}
export const warehouseindex = (params) => {
    return $get(api+'/idsp-pc/warehouse/list/index',params)
}
export const logisticsindex = (params) => {
    return $get(api+'/idsp-pc/logistics/list/index',params)
}
export const wareindex = (params) => {
    return $get(api+'/idsp-pc/warehouse/index',params)
}
export const consultMessage = (params) => {
    return $post(api+'/idsp-admin/consult/message',params)
}
