const compnumber = () => import('../../views/znzz/compnumber.vue');
const sysc = () => import('../../views/znzz/sysc.vue');
const wuliu = () => import('../../views/znzz/wuliu.vue');
const shengchan = () => import('../../views/znzz/shengchan.vue');
const shebei = () => import('../../views/znzz/shebei.vue');
const shuzi = () => import('../../views/znzz/shuzi.vue');
const index = () => import('../../views/home/index.vue');
const didaima = () => import('../../views/znzz/didaima.vue');
const anquan = () => import('../../views/znzz/anquan.vue');
const biaoshi = () => import('../../views/znzz/biaoshi.vue');
const biaoshi1 = () => import('../../views/znzz/biaoshi1.vue');
const map = () => import('../../views/znzz/map.vue');
export default [ {
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/compnumber',
        name: 'compnumber',
        component: compnumber,
    },{
        path: '/sysc',
        name: 'sysc',
        component: sysc,
    },{
        path: '/wuliu',
        name: 'wuliu',
        component: wuliu,
    },{
        path: '/shengchan',
        name: 'shengchan',
        component: shengchan,
    },{
        path: '/shebei',
        name: 'shebei',
        component: shebei,
    },{
        path: '/shuzi',
        name: 'shuzi',
        component: shuzi,
    },{
        path: '/didaima',
        name: 'didaima',
        component: didaima,
    },{
        path: '/anquan',
        name: 'anquan',
        component: anquan,
    },{
        path: '/biaoshi',
        name: 'biaoshi',
        component: biaoshi,
    },{
        path: '/biaoshi1',
        name: 'biaoshi1',
        component: biaoshi1,
    },{
        path: '/map',
        name: 'map',
        component: map,
    }]
}
];
