export default{
    // 检查手机号
    isCellPhone(val){
        if(!/^1(3|4|5|6|7|8|9)\d{9}$/.test(val)){
            return false;
        }else{
            return true
        }
    },
    addLocalStorage(key, value){
        return localStorage.setItem(key, JSON.stringify(value))
    },
    getLocalStorage(key)  {
        const str = localStorage.getItem(key);
        try{
            return  JSON.parse(str)
        }catch(e){
            return null;
        }
    },
    addSessionStorage (key, value) {
        return sessionStorage.setItem(key, JSON.stringify(value))
    },
    removeSessionStorage (key, value) {
        return sessionStorage.removeItem(key)
    },
    getSessionStorage (key)  {
        const str = sessionStorage.getItem(key);
        try{
            return  JSON.parse(str)
        }catch(e){
            return null;
        }
    }

}
