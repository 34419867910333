const parkxmsb = () => import('../../views/park/xmsb.vue');
const yqgl = () => import('../../views/park/yqgl.vue');
const parkindex = () => import('../../views/park/index.vue');
const yqglxq = () => import('../../views/park/yqglxq.vue');
const yqglcompxq = () => import('../../views/park/yqglcompxq.vue');
const index = () => import('../../views/home/index.vue');
const parkxmsbxq = () => import('../../views/park/xmsbxq.vue');
const parkgonggaoxq = () => import('../../views/park/gonggaoxq.vue');
const parkgonggaolist = () => import('../../views/park/gonggaolist.vue');
const parkzchj = () => import('../../views/park/zchj.vue');
export default [ {
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/parkindex',
        name: 'parkindex',
        component: parkindex,
    },{
        path: '/yqgl',
        name: 'yqgl',
        component: yqgl,
    },{
        path: '/yqglxq',
        name: 'yqglxq',
        component: yqglxq,
    },{
        path: '/yqglcompxq',
        name: 'yqglcompxq',
        component: yqglcompxq,
    },{
        path: '/parkxmsb',
        name: 'parkxmsb',
        component: parkxmsb,
    },{
        path: '/parkxmsbxq',
        name: 'parkxmsbxq',
        component: parkxmsbxq,
    },{
        path: '/parkgonggaolist',
        name: 'parkgonggaolist',
        component: parkgonggaolist,
    },{
        path: '/parkgonggaoxq',
        name: 'parkgonggaoxq',
        component: parkgonggaoxq,
    },{
        path: '/parkzchj',
        name: 'parkzchj',
        component: parkzchj,
    }]
}
];
