const djyl = () => import('../../views/djyl/index.vue');
const index = () => import('../../views/home/index.vue');
const zjdj = () => import('../../views/djyl/zjdj.vue');
const zjdjxq = () => import('../../views/djyl/zjdjxq.vue');
export default [ {
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/djyl',
        name: 'djyl',
        component: djyl,
    },{
        path: '/zjdj',
        name: 'zjdj',
        component: zjdj,
    },{
        path: '/zjdjxq',
        name: 'zjdjxq',
        component: zjdjxq,
    }]
}
];
