const zjzx = () => import('../../views/zjzx/index.vue');
const index = () => import('../../views/home/index.vue');
const zjzxxq = () => import('../../views/zjzx/zjzxxq.vue');
const zjzxlist = () => import('../../views/zjzx/list.vue');
export default [ {
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/zjzx',
        name: 'zjzx',
        component: zjzx,
    },{
        path: '/zjzxlist',
        name: 'zjzxlist',
        component: zjzxlist,
    },{
        path: '/zjzxxq',
        name: 'zjzxxq',
        component: zjzxxq,
    }]
}
];
