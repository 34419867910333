import Vue from 'vue'
import VueRouter from 'vue-router'
import home from './home';
import djyl from './djyl';
import gxjy from './gxjy';
import zcfw from './zcfw';
import zxfw from './zxfw';
import zxpx from './zxpx';
import zjzx from './zjzx';
import znzz from './znzz';
import login from './login';
import rlzy from './rlzy';
import jrfw from './jrfw';
import common from './common';
import park from './park';
import tzhh from './tzhh';
import logistics from './logistics';

const routes = [...home,...djyl,...gxjy,...zcfw,...zxfw,...zxpx,...zjzx,...login,...rlzy,...common,...park,...logistics,...jrfw,...znzz,...tzhh];
Vue.use(VueRouter)

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: "/",
  routes
})

// 路由前置钩子(路由守卫)
router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  // chrome
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0; // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
  //to 即将进入的路由
  //from 即将离开的路由
  //next 放行
});

export default router
