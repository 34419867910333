const zxfw = () => import('../../views/zxfw/index.vue');
const index = () => import('../../views/home/index.vue');
const zxfwlist = () => import('../../views/zxfw/list.vue');
const zxfwxq = () => import('../../views/zxfw/zxfwxq.vue');
export default [ {
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/zxfw',
        name: 'zxfw',
        component: zxfw,
    },{
        path: '/zxfwlist',
        name: 'zxfwlist',
        component: zxfwlist,
    },{
        path: '/zxfwxq',
        name: 'zxfwxq',
        component: zxfwxq,
    },]
}
];
