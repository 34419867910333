
import * as index from './moudle/index' // 首页接口
import * as zcfw from './moudle/zcfw' // 首页接口
import * as park from './moudle/park' // 首页接口
import * as logistics from './moudle/logistics' // 首页接口
import * as demand from './moudle/demand' // 首页接口
import * as zjzx from './moudle/zjzx' // 首页接口
import * as zxpx from './moudle/zxpx' // 首页接口
import * as financing from './moudle/financing' // 首页接口
import * as rlzy from './moudle/rlzy' // 首页接口
import * as zxfw from './moudle/zxfw' // 首页接口
import * as znzz from './moudle/znzz' // 首页接口
import * as tzhh from './moudle/tzhh' // 首页接口
export default {
    index,
    zcfw,
    park,
    logistics,
    demand,
    zjzx,
    zxpx,
    financing,
    rlzy,
    zxfw,
    znzz,
    tzhh
}