const xmsb = () => import('../../views/zcfw/xmsb.vue');
const index = () => import('../../views/home/index.vue');
const xmsbxq = () => import('../../views/zcfw/xmsbxq.vue');
const gonggaoxq = () => import('../../views/zcfw/gonggaoxq.vue');
const gonggaolist = () => import('../../views/zcfw/gonggaolist.vue');
const zcjd = () => import('../../views/zcfw/zcjd.vue');
const zchj = () => import('../../views/zcfw/zchj.vue');
export default [ {
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/xmsb',
        name: 'xmsb',
        component: xmsb,
    },{
        path: '/xmsbxq',
        name: 'xmsbxq',
        component: xmsbxq,
    },{
        path: '/gonggaolist',
        name: 'gonggaolist',
        component: gonggaolist,
    },{
        path: '/gonggaoxq',
        name: 'gonggaoxq',
        component: gonggaoxq,
    },{
        path: '/zchj',
        name: 'zchj',
        component: zchj,
    },{
        path: '/zcjd',
        name: 'zcjd',
        component: zcjd,
    }]
}
];
