<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    provide() {
        return {
            
        };
    },
    data() {
        return {
            
        };
    },
    computed: {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
};
</script>


<style lang="scss" scoped>
  #app {
      font-size: 14px;
  }
</style>
