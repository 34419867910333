const index = () => import('../../views/home/index.vue');
const home = () => import('../../views/home/home.vue');
const zhuce = () => import('../../views/home/zhuce.vue');
const personindex = () => import('../../views/home/personindex.vue');
const compyindex = () => import('../../views/home/compyindex.vue');
const chome = () => import('../../views/home/chome.vue');
const czp = () => import('../../views/home/czp.vue');
const cgx = () => import('../../views/home/cgx.vue');
const wdsw = () => import('../../views/home/wdsw.vue');
const qyyd = () => import('../../views/home/qyyd.vue');
const jryd = () => import('../../views/home/jryd.vue');
export default [ {
    path: '/qyyd',
    name: 'qyyd',
    component: qyyd
},
    {
        path: '/jryd',
        name: 'jryd',
        component: jryd
    },
    {
        path: '/zhuce',
        name: 'zhuce',
        component: zhuce
    },{
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/home',
        name: 'home',
        component: home,
    },{
        path: '/personindex',
        name: 'personindex',
        component: personindex,
    },{
        path: '/compyindex',
        name: 'compyindex',
        component: compyindex,
        redirect: '/chome',
        children:[{
            path: '/chome',
            name: 'chome',
            component: chome,
        },{
            path: '/czp',
            name: 'czp',
            component: czp,
        },{
            path: '/cgx',
            name: 'cgx',
            component: cgx,
        }]
    },{
        path: '/wdsw',
        name: 'wdsw',
        component: wdsw,
    }]
}
];
