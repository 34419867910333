import {$get, $post} from '../http';
import {baseUrl} from "@/request/global";

var api = baseUrl
export const getindexindex = (params) => {
    return $get(api+'/idsp-pc/index/index',params)
}
export const enterLogin = (params) => {
    return $post(api+'/idsp-pc/login/enterprise-login',params)
}
export const personLogin = (params) => {
    return $post(api+'/idsp-pc/login/person-login',params)
}
export const personRegister = (params) => {
    return $post(api+'/idsp-pc/login/person-register',params)
}
export const saveEnterprisePerson = (params) => {
    return $post(api+'/idsp-pc/public/enterprise/saveEnterprisePerson',params)
}
export const checkEmailIsExist = (params) => {
    return $post(api+'/idsp-pc/login/validate-email',params)
}
export const sendValidateCode = (params) => {
    return $post(api+'/idsp-pc/login/send-email-verification-code',params)
}
export const checkEmailValidateCode = (params) => {
    return $post(api+'/idsp-pc/login/validate-email-code',params)
}
export const updatePasswordByEmail = (params) => {
    return $post(api+'/idsp-pc/login/reset-password',params)
}
export const getEnterpriseMain = (params) => {
    return $post(api+'/idsp-pc/public/enterprise/getEnterpriseMain',params)
}
export const getProductListByCreditCode = (params) => {
    return $post(api+'/idsp-pc/public/enterprise/getProductListByCreditCode',params)
}
export const reqnewenterprise = (params) => {
    return $post(api+'/idsp-pc/login/req-new-enterprise',params)
}
export const enterpriselist = (params) => {
    return $get(api+'/idsp-pc/login/enterprise-list',params)
}
export const reqrelateenterprise = (params) => {
    return $post(api+'/idsp-pc/login/req-relate-enterprise',params)
}
export const batchupload = (params) => {
    return $post(api+'/blade-resource/oss/endpoint/put-file',params)
}
export const dictbizlist = (params) => {
    return $get(api+'/blade-system/dict-biz/list',params)
}
export const loginindex = (params) => {
    return $get(api+'/idsp-pc/login/index',params)
}
export const authorize = (params) => {
    return $get(api+'/blade-auth/oauth/authorize',params)
}
export const logout = (params) => {
    return $get(api+'/blade-auth/oauth/logout',params,{type:3})
}
export const lazylist = (params) => {
    return $get(api+'/blade-system/region/lazy-list',params)
}
export const indexpublic = (params) => {
    return $get(api+'/idsp-pc/index/public',params)
}
export const aboutindex = (params) => {
    return $get(api+'/idsp-pc/about/index',params)
}
export const faqlist = (params) => {
    return $get(api+'/idsp-pc/about/direct-appeal/faq-list',params)
}
export const appealindex = (params) => {
    return $get(api+'/idsp-pc/about/direct-appeal/index',params)
}
export const directappealconsult = (params) => {
    return $post(api+'/idsp-pc/about/direct-appeal/consult',params)
}
export const myconsult = (params) => {
    return $get(api+'/idsp-pc/about/direct-appeal/my-consult',params)
}
export const homeData = (params) => {
    return $get(api+'/idsp-pc/party/homeData',params)
}
export const biddinglist = (params) => {
    return $get(api+'/idsp-pc/tendering-bidding-nation/list',params)
}
export const biddingdetail = (params) => {
    return $get(api+'/idsp-pc/tendering-bidding-nation/detail',params)
}
export const logininit = (params) => {
    return $get(api+'/idsp-pc/login/login-index',params)
}
export const userinfo = (params) => {
    return $get(api+'/blade-system/user/info',params)
}

export const getDepts = (params) => {
    return $get(api+'/blade-system/dept/lazy-tree-all',params)
}
