import {$get} from '../http';
import {baseUrl} from "@/request/global";

var api = baseUrl
export const parklist = (params) => {
    return $get(api+'/idsp-pc/park/list',params)
}
export const parkdetail = (params) => {
    return $get(api+'/idsp-pc/park/detail',params)
}
export const enterpriselist = (params) => {
    return $get(api+'/idsp-pc/park/enterprise-list',params)
}
export const enterprisedetail = (params) => {
    return $get(api+'/idsp-pc/park/enterprise-detail',params)
}

export const policyprojectindex = (params) => {
    return $get(api+'/idsp-pc/park-project/index',params)
}
export const policyprojectpage = (params) => {
    return $get(api+'/idsp-pc/park-project/page',params)
}
export const policyprojectdetail = (params) => {
    return $get(api+'/idsp-pc/park-project/detail',params)
}

export const parkpolicyindex = (params) => {
    return $get(api+'/idsp-pc/park-policy/index',params)
}
export const parkpolicypage = (params) => {
    return $get(api+'/idsp-pc/park-policy/page',params)
}
export const parkpolicydetail = (params) => {
    return $get(api+'/idsp-pc/park-policy/detail',params)
}
export const parkindex = (params) => {
    return $get(api+'/idsp-pc/park/index',params)
}
