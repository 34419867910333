import {$get} from '../http';
import {baseUrl} from "@/request/global";

var api = baseUrl
export const financingpage = (params) => {
    return $get(api+'/idsp-pc/financing/page',params)
}
export const financingindex = (params) => {
    return $get(api+'/idsp-pc/financing/index',params)
}
export const financingdetail = (params) => {
    return $get(api+'/idsp-pc/financing/detail',params)
}
