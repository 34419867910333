const rlzys = () => import('../../views/rlzy/index.vue');
const index = () => import('../../views/home/index.vue');
const rlzylist = () => import('../../views/rlzy/rlzylist.vue');
const rlzyfind = () => import('../../views/rlzy/rlzyfind.vue');
const rlzypeople = () => import('../../views/rlzy/rlzypeople.vue');
export default [ {
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/rlzy',
        name: 'rlzy',
        component: rlzys,
    },{
        path: '/rlzylist',
        name: 'rlzylist',
        component: rlzylist,
    },{
        path: '/rlzyfind',
        name: 'rlzyfind',
        component: rlzyfind,
    },{
        path: '/rlzypeople',
        name: 'rlzypeople',
        component: rlzypeople,
    }]
}
];
