const search = () => import('../../views/common/search.vue');
const about = () => import('../../components/about.vue');
const index = () => import('../../views/home/index.vue');
export default [ {
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/search',
        name: 'search',
        component: search
    },{
        path: '/about',
        name: 'about',
        component: about
    },]
}
];
