import {$get, $post} from '../http';
import {baseUrl} from "@/request/global";

var api = baseUrl
export const policyindex = (params) => {
    return $get(api+'/idsp-pc/policy/index',params)
}
export const policyunscrambleindex = (params) => {
    return $get(api+'/idsp-pc/policy-unscramble/index',params)
}
export const policyprojectindex = (params) => {
    return $get(api+'/idsp-pc/policy-project/index',params)
}
export const policypage = (params) => {
    return $get(api+'/idsp-pc/policy/page',params)
}
export const policyunscramblepage = (params) => {
    return $get(api+'/idsp-pc/policy-unscramble/page',params)
}
export const policyprojectpage = (params) => {
    return $get(api+'/idsp-pc/policy-project/page',params)
}
export const policydetail = (params) => {
    return $get(api+'/idsp-pc/policy/detail',params)
}
export const policyunscrambledetail = (params) => {
    return $get(api+'/idsp-pc/policy-unscramble/detail',params)
}
export const policyprojectdetail = (params) => {
    return $get(api+'/idsp-pc/policy-project/detail',params)
}
export const policymergeDetail = (params) => {
    return $get(api+'/idsp-pc/policy/mergeDetail',params)
}
export const noticeselect = (params) => {
    return $get(api+'/idsp-pc/notice/select',params)
}
export const noticedetail = (params) => {
    return $get(api+'/idsp-pc/notice/detail',params)
}
export const enterLogin = (params) => {
    return $post(api+'/idsp-pc/login/enterprise-login',params)
}
export const saveReq = (params) => {
    return $post(api+'/idsp-pc/policy-project/save-req',params)
}


