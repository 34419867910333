import {$get} from '../http';
import {baseUrl} from "@/request/global";

var api = baseUrl
export const broadcastcenterindex = (params) => {
    return $get(api+'/idsp-pc/invest-hh/broadcast-center-index',params)
}
export const broadcastcenterlist = (params) => {
    return $get(api+'/idsp-pc/invest-hh/broadcast-center-list',params)
}
export const foundersaidindex = (params) => {
    return $get(api+'/idsp-pc/invest-hh/founder-said-index',params)
}
export const landmarkbrandindex = (params) => {
    return $get(api+'/idsp-pc/invest-hh/landmark-brand-index',params)
}
