import {$get, $post} from '../http';
import {baseUrl} from "@/request/global";

var api = baseUrl
export const tenderinglist = (params) => {
    return $get(api+'/idsp-pc/tendering-bidding/list',params)
}
export const getIndustryList = (params) => {
    return $get(api+'/idsp-pc/tendering-bidding/getIndustryList',params)
}
export const getTypeList = (params) => {
    return $get(api+'/idsp-pc/tendering-bidding/getTypeList',params)
}
export const submitconsult = (params) => {
    return $post(api+'/idsp-pc/tendering-bidding/submit-consult',params)
}
export const tenderingdetail = (params) => {
    return $get(api+'/idsp-pc/tendering-bidding/detail',params)
}
export const purchaselist = (params) => {
    return $get(api+'/idsp-pc/purchase/list',params)
}
export const purchasedetail = (params) => {
    return $get(api+'/idsp-pc/purchase/detail',params)
}
export const purchasegetIndustryList = (params) => {
    return $get(api+'/idsp-pc/purchase/getIndustryList',params)
}
export const purchasegetTypeList = (params) => {
    return $get(api+'/idsp-pc/purchase/getTypeList',params)
}
export const purchasesubmitconsult = (params) => {
    return $post(api+'/idsp-pc/purchase/submit-consult',params)
}
export const inventorylist = (params) => {
    return $get(api+'/idsp-pc/inventory/list',params)
}
export const inventorydetail = (params) => {
    return $get(api+'/idsp-pc/inventory/detail',params)
}
export const inventorygetIndustryList = (params) => {
    return $get(api+'/idsp-pc/inventory/getIndustryList',params)
}
export const inventorygetTypeList = (params) => {
    return $get(api+'/idsp-pc/inventory/getTypeList',params)
}
export const inventorysubmitconsult = (params) => {
    return $post(api+'/idsp-pc/inventory/submit-consult',params)
}
export const supplydemandlist = (params) => {
    return $get(api+'/idsp-pc/supply-demand/list',params)
}
export const supplydemandgetTypeList = (params) => {
    return $get(api+'/idsp-pc/supply-demand/getTypeList',params)
}
export const supplydemandindex = (params) => {
    return $get(api+'/idsp-pc/supply-demand/index',params)
}
