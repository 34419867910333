
const index = () => import('../../views/home/index.vue');
const tzhh = () => import('../../views/tzhh/index.vue');
const ppgs = () => import('../../views/tzhh/ppgs.vue');
const csrs = () => import('../../views/tzhh/csrs.vue');
const zbzx = () => import('../../views/tzhh/zbzx.vue');
export default [ {
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/tzhh',
        name: 'tzhh',
        component: tzhh,
    },{
        path: '/ppgs',
        name: 'ppgs',
        component: ppgs,
    },{
        path: '/csrs',
        name: 'csrs',
        component: csrs,
    },{
        path: '/zbzx',
        name: 'zbzx',
        component: zbzx,
    }]
}
];
