export const gotoUrl = (url, params) => {
  let paramsStr = '';
  for (let key in params) {
    paramsStr += key + '=' + encodeURI(params[key]) + '&';
  }
  return url + '?' + paramsStr;
}
export default {
  pchref: `${process.env.VUE_APP_URL_PC}/login`,  // 授权回调地址
  adminhref: `${process.env.VUE_APP_URL_ADMIN}/`,  // 授权回调地址
  ssoUrl: `${process.env.VUE_APP_AUTH_URL}/oauth/authorize`,
  clientId: 'pc',
  clientKey: 'idsp-pc',
  gotoUrl,
  getSsoUrl(params) {
    params.client_id = this.clientId;
    params.response_type = 'code';
    params.redirect_uri = this.pchref;
    params.failureUrl = this.pchref;
    params.jumpUrl = window.location.href;
    return gotoUrl(this.ssoUrl, params);
  }
}
