const zxpx = () => import('../../views/zxpx/index.vue');
const index = () => import('../../views/home/index.vue');
const zxpxxq = () => import('../../views/zxpx/zxpxxq.vue');
const zxpxlist = () => import('../../views/zxpx/list.vue');
export default [ {
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/zxpx',
        name: 'zxpx',
        component: zxpx,
    },{
        path: '/zxpxlist',
        name: 'zxpxlist',
        component: zxpxlist,
    },{
        path: '/zxpxxq',
        name: 'zxpxxq',
        component: zxpxxq,
    }]
}
];
