import {$get} from '../http';
import {baseUrl} from "@/request/global";

var api = baseUrl
export const intermediaryindex = (params) => {
    return $get(api+'/idsp-pc/intermediary/index',params)
}
export const intermediarylistindex = (params) => {
    return $get(api+'/idsp-pc/intermediary/list/index',params)
}
export const intermediarypage = (params) => {
    return $get(api+'/idsp-pc/intermediary/page',params)
}
export const intermediarydetail = (params) => {
    return $get(api+'/idsp-pc/intermediary/detail',params)
}
