const financing = () => import('../../views/financing/fiindex.vue');
const index = () => import('../../views/home/index.vue');
const financingxq = () => import('../../views/financing/financingxq.vue');
export default [ {
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/financing',
        name: 'financing',
        component: financing,
    },{
        path: '/financingxq',
        name: 'financingxq',
        component: financingxq,
    }]
}
];
