import {$get} from '../http';
import {baseUrl} from "@/request/global";

var api = baseUrl
export const onlinepage = (params) => {
    return $get(api+'/idsp-pc/online-training/page',params)
}
export const onlineindex = (params) => {
    return $get(api+'/idsp-pc/online-training/page/index',params)
}
export const onlinedetail = (params) => {
    return $get(api+'/idsp-pc/online-training/detail',params)
}
export const onlinetrainingindex = (params) => {
    return $get(api+'/idsp-pc/online-training/index',params)
}
